import './App.css';
import { useState, useEffect, Suspense, lazy, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { handleRefresh } from './RefreshUtils';

import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

const WalletsManageLazy = lazy(() => import('./walletsManage'));
const TalentSearchLazy = lazy(() => import('./talentSearch'));
const MainMenuLazy = lazy(() => import('./mainMENU'));
const GamingModalLazy = lazy(() => import('./gamingModal'));
const ConnectoLazy = lazy(() => import('./Connecto'));
const ConnectoSignupLazy = lazy(() => import('./Connecto-signup'));


require('dotenv').config();

interface HeaderProps {
  hasWallet: boolean;
  wallet: string;
  ethBalanceInDollars: number | null;
  selectedCurrency: string;
  isWalletMenuOpen: boolean;
  handleToggleWalletMenu: () => void;
  activeSection: string; // This is correctly typed as a string.
  setActiveSection: (section: string) => void; 
  whatPage: string;
  setWhatPage: (page: string) => void; 
  currentURLState: number; 
  setCurrentURLState: (state: number) => void; 
  onGame: boolean;
  handleLinkClick: (page: string, section: string) => void;
}

function Header({
  hasWallet,
  wallet,
  ethBalanceInDollars,
  selectedCurrency,
  isWalletMenuOpen,
  handleToggleWalletMenu,
  activeSection,
  setActiveSection,
  whatPage,
  setWhatPage,
  currentURLState,
  setCurrentURLState,
  onGame
}: HeaderProps) {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMarketPage, setIsMarketPage] = useState(false);
  const [isTeamPage, setIsTeamPage] = useState(false);
  const [isCompetitionPage, setIsCompetitionPage] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isDashboardPage, setIsDashboardPage] = useState(false);
  const [isVisitor, setIsVisitor] = useState(true);

  const walletAddress = localStorage.getItem('walletAddress');
  const cachedUser = JSON.parse(localStorage.getItem('cachedUser') || '{}');

  const [indexLanguage, setIndexLanguage] = useState('en');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
    setIsMarketPage(whatPage.includes('market'));
    setIsTeamPage(whatPage.includes('team'));
    setIsProfilePage(whatPage.includes('/profile'));
    setIsDashboardPage(whatPage.includes('dashboard'));
    setIsCompetitionPage(whatPage.includes('competition'));
    const currentWallet = localStorage.getItem('walletAddress');
    if (currentWallet === '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a') {
      setIsVisitor(true);
    } else {
      setIsVisitor(false);
    }
    
  }, []); // Empty dependency array ensures this runs once on mount



  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (cachedUser && cachedUser.wallet === walletAddress) {
          // Use cached data if the wallet address is the same
          setUser(cachedUser.userData);
        } else {
          const response = await fetch(
            `${linkUrl}/wp-json/custom/v1/get_user_by_metadata/?value=${walletAddress}`
          );
          const userData = await response.json();

          if (userData && userData.length > 0) {
            setUser(userData[0]);
            // Cache the user data
            localStorage.setItem('cachedUser', JSON.stringify({ wallet: walletAddress, userData: userData[0] }));
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [cachedUser, walletAddress]);

  /*const handleLinkClick = (page:any, section:any) => {
    setWhatPage(page);
    setActiveSection(section);
    handleRefresh();
  };*/

  const handleLinkClick = (page:any, section:any) => {
    const newUrl = `${linkUrl}/game/?page=${encodeURIComponent(page)}&section=${encodeURIComponent(section)}`;
    window.history.pushState({}, '', newUrl); // Update the URL
    localStorage.setItem('pxlPage',page);
    localStorage.setItem('pxlSection',section);
    setCurrentURLState(currentURLState +1);
  };

  const isMobileBreakpoint = 768; // Adjust this value as needed
  const isPeaxelMobile = window.innerWidth <= isMobileBreakpoint;
  const isPeaxelHome = window.location.href === `${linkUrl}/` || isPeaxelMobile;
  const context = 'primary';

  const classNameCompetition = whatPage.includes('competition') ? 'activeItem' : 'nonActiveItem';
  const classNameMarket = whatPage.includes('market') ? 'activeItem' : 'nonActiveItem';
  const classNameTeam = whatPage.includes('team') ? 'activeItem' : 'nonActiveItem';
  const classNameDashboard = whatPage.includes('dashboard') ? 'activeItem' : 'nonActiveItem';
  const classNameProfile = whatPage.includes('profile') ? 'activeItem' : 'nonActiveItem';
  const classNameTest = whatPage.includes('/testzone') ? 'show' : 'noShow';

  const classAllAuctions = (activeSection === 'allAuctions') ? 'menuTagActive' : 'menuTag';
  const classAllTalents = (activeSection === 'allTalents') ? 'menuTagActive' : 'menuTag';
  const classManagersSales = (activeSection === 'managersSales') ? 'menuTagActive' : 'menuTag';
  const classOffersReceived = (activeSection === 'offersReceived') ? 'menuTagActive' : 'menuTag';
  const classOffersSent = (activeSection === 'offersSent') ? 'menuTagActive' : 'menuTag';
  const classListedTalents = (activeSection === 'listedTalents') ? 'menuTagActive' : 'menuTag';
  const classOverview = (activeSection === 'overView') ? 'menuTagActive' : 'menuTag';
  const classAllMyCards = (activeSection === 'allMyCards') ? 'menuTagActive' : 'menuTag';
  const classAllMyTalents = (activeSection === 'allMyTalents') ? 'menuTagActive' : 'menuTag';
  const classLineup = (activeSection === 'myLineup') ? 'menuTagActive' : 'menuTag';
  const classInsights = (activeSection === 'insights') ? 'menuTagActive' : 'menuTag';
  const classTalentNews = (activeSection === 'talentNews') ? 'menuTagActive' : 'menuTag';
  const classResults = (activeSection === 'results') ? 'menuTagActive' : 'menuTag';

  const classNameHome = isPeaxelHome ? 'atHome' : 'notAtHome';
  const classNameButton = hasWallet ? 'noShow' : 'custom-button';
  const classNameButtonOpt2 = window.innerWidth <= 768 ? 'custom-button-mobile' : 'custom-button';

  const talentProfile = localStorage.getItem('Nprofile');



  return (
    <header className="sticky-header">
      <div className="div_sticky">
        <div className="div_substicky">
          
          <div className="rowHeader" >
          
          
          <div className="gridcell_sticky1">
            
          

            <div className="newMENU">
            {onGame === true && (
                <div>
                <a href={`${linkUrl}/`}>
                <img
  src={`${linkUrl}/wp-content/uploads/2024/06/Logo_peaxel_transparent.png`}
  alt="Peaxel Logo"
  style={{ width: '32px', height: 'auto', cursor: 'pointer' }}
/>
                </a>
              </div>
            )}

            {(onGame === true && !isPeaxelMobile) && (
                <div>
                <span>&nbsp;&nbsp;&nbsp;</span>
                </div>
              )}

            {(onGame === true && !isPeaxelMobile) && (
              <div className={classNameDashboard}>
                <span className={classNameHome}  onClick={() => handleLinkClick('dashboard','insights')}>{t('dashBoard')}</span>
                </div>
              )}

            {(onGame === true && !isPeaxelMobile) && (
              <div className={classNameMarket}>
                <span className={classNameHome}  onClick={() => handleLinkClick('market','allTalents')}>{t('market')}</span>
              </div>
              )}

            {(onGame === true && !isPeaxelMobile) && (
              <div className={classNameTeam}>
              <span className={classNameHome}  onClick={() => handleLinkClick('team','overView')}>{t('team')}</span>
              </div>
            )}

            {(hasWallet && onGame === true && !isPeaxelMobile) && (
              <div className={classNameCompetition}>
              <span className={classNameHome}  onClick={() => handleLinkClick('competition','myLineup')}>{t('competition')}</span> 
              </div>
              )}


            </div>




          </div>
            
            
            <div className="gridcell_sticky2">
              
              
            {(hasWallet === true && isVisitor === true && onGame === true) && (
                  
                  <div className="synch-logo">
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <TalentSearchLazy 
                  handleLinkClick={handleLinkClick}/>
                  </Suspense>

                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <ConnectoSignupLazy />
                  </Suspense>
                  
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <ConnectoLazy />
                  </Suspense>
      
                  
      
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <MainMenuLazy setActiveSection={setActiveSection} setWhatPage={setWhatPage} currentURLState={currentURLState} setCurrentURLState={setCurrentURLState} />
                  </Suspense>
                  </div>
                )}

                
            {(isVisitor === false && onGame === true) && (
                  
                  <div className="synch-logo">
                    
                  <div onClick={() => handleLinkClick('market','offersReceived')}>
      
                  <div id="displayPendingOffers"></div>
                  
                  </div>

                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <GamingModalLazy />
                  </Suspense>
                    
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <WalletsManageLazy />
                  </Suspense>
      
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <TalentSearchLazy 
                  handleLinkClick={handleLinkClick}/>
                  </Suspense>
      
                  <Suspense fallback={<div>{t('loading')}</div>}>
                  <MainMenuLazy setActiveSection={setActiveSection} setWhatPage={setWhatPage} currentURLState={currentURLState} setCurrentURLState={setCurrentURLState} />
                  </Suspense>
                  </div>
                )}
              
            </div>
          </div>
        
          {(onGame === true && isDashboardPage && !isPeaxelMobile) && (
          <div>
            <div className="subMenu">
            <span className={classInsights} onClick={() => handleLinkClick('dashboard','insights')}>{t('insights')}</span>
            <span className={classTalentNews} onClick={() => handleLinkClick('dashboard','talentNews')}>{t('talentNews')}</span>
             </div>
          </div>
          )}

          {(onGame === true && isMarketPage && !isPeaxelMobile) && (
          <div>
            <div className="subMenu">
            <span className={classAllTalents} onClick={() => handleLinkClick('market','allTalents')}>{t('allTalents')}</span>
            <span className={classAllAuctions} onClick={() => handleLinkClick('market','allAuctions')}>{t('allAuctions')}</span>
            <span className={classManagersSales}  onClick={() => handleLinkClick('market','managersSales')}>{t('managersSales')}</span>
            <span className={classOffersReceived}  onClick={() => handleLinkClick('market','offersReceived')}>{t('offersReceived')}</span>
            <span className={classOffersSent}  onClick={() => handleLinkClick('market','offersSent')}>{t('offersSent')}</span>
            <span className={classListedTalents}  onClick={() => handleLinkClick('market','listedTalents')}>{t('listedTalents')}</span>
            </div>
          </div>
          )}
          
          {(onGame === true && isTeamPage && !isPeaxelMobile) && (
          <div>
            <div className="subMenu"> 
            <span className={classOverview} onClick={() => handleLinkClick('team','overView')}>{t('overview')}</span>
            <span className={classAllMyTalents}  onClick={() => handleLinkClick('team','allMyTalents')}>{t('allMyTalents')}</span>
            <span className={classAllMyCards} onClick={() => handleLinkClick('team','allMyCards')}>{t('allMyCards')}</span>
            </div>  
          </div>
          )}

          {(onGame === true && isCompetitionPage && !isPeaxelMobile) && (
          <div>
            <div className="subMenu"> 
            <span className={classLineup} onClick={() => handleLinkClick('competition','myLineup')}>{t('myLineup')}</span>
            <span className={classResults} onClick={() => handleLinkClick('competition','results')}>{t('results')}</span>
             </div>  
          </div>
          )}


         </div>
      </div>

      {(onGame === true && (isMarketPage || isTeamPage || isDashboardPage) && isPeaxelMobile || isCompetitionPage) && (

<div className="slidingMenuMob">

{(onGame === true && isDashboardPage && isPeaxelMobile) && (
          <div>
           
            <div className="subMenuMob">
            <span className={classInsights} onClick={() => handleLinkClick('dashboard','insights')}>{t('insights')}</span>
            <span className={classTalentNews} onClick={() => handleLinkClick('dashboard','talentNews')}>{t('talentNews')}</span>
             </div>
           
          </div>
          )}


{(onGame === true && isMarketPage && isPeaxelMobile) && (
          <div>
            <div className="arrow arrow-left">&#9664;</div> 
            <div className="subMenuMob">
            <span className={classAllTalents} onClick={() => handleLinkClick('market','allTalents')}>{t('allTalents')}</span>
            <span className={classAllAuctions} onClick={() => handleLinkClick('market','allAuctions')}>{t('allAuctions')}</span>
            <span className={classManagersSales}  onClick={() => handleLinkClick('market','managersSales')}>{t('managersSales')}</span>
            <span className={classOffersReceived}  onClick={() => handleLinkClick('market','offersReceived')}>{t('offersReceived')}</span>
            <span className={classOffersSent}  onClick={() => handleLinkClick('market','offersSent')}>{t('offersSent')}</span>
            <span className={classListedTalents}  onClick={() => handleLinkClick('market','listedTalents')}>{t('listedTalents')}</span>
            </div>
            <div className="arrow arrow-right">&#9654;</div>
          </div>
          )}
          
          {(onGame === true && isTeamPage && isPeaxelMobile) && (
          <div>
            <div className="arrow arrow-left">&#9664;</div> 
            <div className="subMenuMob"> 
            <span className={classOverview} onClick={() => handleLinkClick('team','overView')}>{t('overview')}</span>
            <span className={classAllMyTalents}  onClick={() => handleLinkClick('team','allMyTalents')}>{t('allMyTalents')}</span>
            <span className={classAllMyCards} onClick={() => handleLinkClick('team','allMyCards')}>{t('allMyCards')}</span>
            </div>  
            <div className="arrow arrow-right">&#9654;</div>
          </div>
          )}

        {(onGame === true && isCompetitionPage && isPeaxelMobile) && (
          <div>
            <div className="arrow arrow-left">&#9664;</div> 
            <div className="subMenuMob"> 
             <span className={classLineup} onClick={() => handleLinkClick('competition','myLineup')}>{t('myLineup')}</span>
            <span className={classResults} onClick={() => handleLinkClick('competition','results')}>{t('results')}</span>
            </div>  
            <div className="arrow arrow-right">&#9654;</div>
          </div>
          )}


</div>
      )}



    </header>
  );
}

Header.propTypes = {
  hasWallet: PropTypes.bool.isRequired,
  wallet: PropTypes.string,
  ethBalanceInDollars: PropTypes.number,
  selectedCurrency: PropTypes.string,
  isWalletMenuOpen: PropTypes.bool.isRequired,
  handleToggleWalletMenu: PropTypes.func.isRequired,
};

export default Header;
